import React, { useState, useEffect, useRef, useContext } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from '@material-ui/lab/Rating';
import UserSelect from "../UserSelect";
import Box from "@material-ui/core/Box";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import { IconButton, InputAdornment, TextareaAutosize } from "@material-ui/core";
import LoggedInLayout from "../../layout";

const labels = {
	0.5: 'Useless',
	1: 'Useless+',
	1.5: 'Poor',
	2: 'Poor+',
	2.5: 'Ok',
	3: 'Ok+',
	3.5: 'Good',
	4: 'Good+',
	4.5: 'Excellent',
	5: 'Excellent+',
};



const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const ReviewSchema = Yup.object().shape({
	review: Yup.string()
		.min(2, "Too Short!")
		.max(500, "Too Long!")
		.required("Required"),
	score: Yup.number().required("Required"),
});

const ReviewModal = ({ open, onClose, reviewId }) => {
	const classes = useStyles();
	const { activeCompanyId } = useContext(AuthContext);
	const companyId = parseInt(activeCompanyId);




	const { user: loggedInUser } = useContext(AuthContext);

	const [selectedUserIds, setSelectedUserIds] = useState([]);

	const greetingRef = useRef();

	const [value, setValue] = useState(2);
	const [hover, setHover] = useState(-1);

	const initialState = {
		review: "",
		rate: 0,
		name: loggedInUser.name,
		email: loggedInUser.email,
	};

	const [review, setReview] = useState(initialState);

	useEffect(() => {
		(async () => {
			if (!reviewId) return;
			try {
				const { data } = await api.get(`/reviews/${reviewId}`);

				setReview(prevState => {
					return { ...prevState, ...data };
				});
				setValue(prevState => { 

					return { ...prevState, ...data.score};
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setReview({
				review: '',
				score: 5,
				name: loggedInUser.name,
				email: loggedInUser.email,
			});
		};
	}, [reviewId, open]);

	const handleClose = () => {
		onClose();
		setReview(initialState);
	};

	const handleSaveReview = async values => {

		

		const userData = { ...values, userIds: selectedUserIds, userId: selectedUserIds[0], score: value, email: loggedInUser.email, companyId: companyId };
		



		try {
			if (reviewId) {
				await api.put(`/reviews/${reviewId}`, userData);
			} else {
				await api.post("/reviews", userData);
			}
			toast.success("Review saved successfully");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{reviewId
						? `${i18n.t("reviewModal.title.edit")}`
						: `${i18n.t("reviewModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={review}
					enableReinitialize={true}
					validationSchema={ReviewSchema}
					onSubmit={(values, actions) => {

						setTimeout(() => {
							handleSaveReview(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<div>
									<Can
										role={loggedInUser.profile}
										perform="user-modal:editReviews"
										yes={() => (
											<UserSelect
												selectedUserIds={selectedUserIds}
												onChange={values => setSelectedUserIds(values)}
											/>
										)}
									/>
								</div>
								<div>
									<Rating
										name="score"
							
										precision={0.5}
										size="large"
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
										onChangeActive={(event, newHover) => {
											setHover(newHover);
										}}
									/>
								</div>
								{value !== null && <Box ml={2}>{labels[hover !== -1 ? hover : value]}</Box>}
								<div>
									<Field
										as={TextField}
										label={i18n.t("reviewModal.form.review")}
										autoFocus
										multiline
										name="review"
										error={touched.review && Boolean(errors.review)}
										helperText={touched.review && errors.review}
										variant="outlined"
										margin="dense"
										rows="5"
										className={classes.textField}
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("reviewModal.form.name")}
										autoFocus
										
										name="name"
										error={touched.review && Boolean(errors.review)}
										helperText={touched.review && errors.review}
										variant="outlined"
										margin="dense"
										rows="5"
										className={classes.textField}

									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("reviewModal.form.email")}
										autoFocus
										
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										rows="5"
										className={classes.textField}

									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("reviewModal.form.number")}
										autoFocus
										
										name="number"
										error={touched.number && Boolean(errors.number)}
										helperText={touched.number && errors.number}
										variant="outlined"
										margin="dense"
										rows="5"
										className={classes.textField}

									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("reviewModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{reviewId
										? `${i18n.t("reviewModal.buttons.okEdit")}`
										: `${i18n.t("reviewModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ReviewModal;
