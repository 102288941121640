import React, { useContext, useEffect, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";






import { Checkbox, ListItemText, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";




const UserCompanySelect = ({
	userCompanies
}) => {
	const { user, activeCompanyId, setActiveCompanyId } = useContext(AuthContext);
	const [visibleCombo, setVisibleCombo] = useState(1);
	const [textAnchor, setTextAnchor] = useState('');

	
	useEffect(() => {
		
		const handleSaveUser = async () => {
			const userData = { lastCompanyId: activeCompanyId  };
			try {
				await api.put(`/userssession/${user.id}`, userData);
			
			} catch (err) {
				console.log(err);
			}
		};
		if (activeCompanyId > 0) {
			
			handleSaveUser();
		}
	}, [activeCompanyId]); 


	const handleChange = (e, row) => {
		//onChange(row.props);
		setActiveCompanyId(row.props.value);
		setTextAnchor(row.props.name);
		setVisibleCombo(0);
	};


	if (!visibleCombo) { 
		return (
			<MenuItem onClick={() => setVisibleCombo(1)}>
				<Typography
					component="h3"
					variant="h6"
					color="inherit"
					noWrap
					
				>
					{textAnchor}
				</Typography>
			</MenuItem>)
	} else { 
		return (<MenuItem>
			<div style={{ width: 120, marginTop: -4 }}>
				<FormControl fullWidth margin="dense">
					<Select

						displayEmpty
						
						variant="outlined"
						value={activeCompanyId}

						onChange={handleChange}

						MenuProps={{
							anchorOrigin: {
								vertical: "bottom",
								horizontal: "left",
							},
							transformOrigin: {
								vertical: "top",
								horizontal: "left",
							},
							getContentAnchorEl: null,
						}}>
						{userCompanies?.length > 0 &&
							userCompanies.map(company => (
								<MenuItem key={`activeCompany${company.id}`} value={company.id} name={company.name}>
									{company.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</div>
		</MenuItem>)
	}
};

export default UserCompanySelect;
