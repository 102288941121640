import React, { Component, useState } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import MuiPhoneNumber from "material-ui-phone-number";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

export class RateFormFinal extends Component {
    constructor(props) {
        super(props);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.setState = this.props.setState.bind(this);
        this.handleSubmit = this.props.handleSubmit.bind(this);
    }

    continue = (e) => {
        //process form

        e.preventDefault();
        if (this.handleSubmit()) {
            this.props.nextStep();
        }
    };

    handlePhoneChange(value) {
        if (value) {
            this.setState({ phone: value });
        }
    }

    render() {
        const handleChange = this.props.handleChange;
        const handleTouch = this.props.handleTouch;
        //const handleSubmit = this.props.handleSubmit;
        const classes = this.props.values.classes;
        const values = this.props.values;
        const errors = this.props.values.errors;
        const touched = this.props.touched;

        return (
            <React.Fragment>
                <MuiThemeProvider>
                    <>
                        <AppBar title="Enter User Details" />

                        <CssBaseline />
                        <Card square className={classes.paper}>
                            <CardMedia
                                className={classes.media}
                                image={values.companyBackground}
                                title={values.companyName}
                            >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    p={2}
                                >
                                    <Avatar
                                        alt={values.companyName}
                                        src={values.companyLogo}
                                        className={classes.medium}
                                    />
                                </Box>
                            </CardMedia>

                            <form
                                noValidate
                                autoComplete="off"
                                onSubmit={this.props.handleSubmit}
                            >
                                <div align="center">
                                    <Typography
                                        className={classes.text}
                                        variant="subtitle1"
                                        align="center"
                                    >
                                        {values.finalMessage}
                                    </Typography>
                                </div>

                                <div align="center">
                                    <TextField
                                        placeholder="Nombre"
                                        label="Nombre"
                                        onChange={handleChange("firstName")}
                                        defaultValue={values.firstName}
                                        name="firstName"
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        className={classes.paddinglr}
                                        onFocus={handleTouch(
                                            "touched_firstName"
                                        )}
                                        error={
                                            this.props.state
                                                .touched_firstName &&
                                            this.props.state.firstName === ""
                                        }
                                        helperText={
                                            this.props.state
                                                .touched_firstName &&
                                            this.props.state.firstName === ""
                                                ? this.props.state
                                                      .error_firstName
                                                : ""
                                        }
                                        required
                                    />
                                </div>

                                <div align="center">
                                    <TextField
                                        placeholder="Email"
                                        label="Email"
                                        onChange={handleChange("email")}
                                        defaultValue={values.email}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        className={classes.paddinglr}
                                        onFocus={handleTouch("touched_email")}
                                        error={
                                            this.props.state.touched_email &&
                                            this.props.state.error_email != ""
                                        }
                                        helperText={
                                            this.props.state.touched_email
                                                ? this.props.state.error_email
                                                : ""
                                        }
                                    />
                                </div>
                                {/*<div align="center" >
                                        <MuiPhoneNumber
                                            defaultCountry='ar'
                                            regions={'america'}
                                            label="Telefono"
                                            margin="normal"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            className={classes.paddinglr}
                                            onFocus={handleTouch('touched_phone')}
                                            error={this.props.state.touched_phone && this.props.state.phone === ''}
                                            helperText={this.props.state.touched_phone && this.props.state.phone === '' ? "este campo es obligatorio" : ''}
                                            onChange={handleChange('phone')}
                                        />
                                        
                                    </div>
                                */}
                                <div align="center">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        style={{
                                            marginTop: 40,
                                            marginBottom: 20,
                                        }}
                                    >
                                        Calificame
                                    </Button>
                                </div>
                            </form>
                        </Card>
                    </>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

export default RateFormFinal;
