import React, { Component } from "react";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

export class RateFormStepB extends Component {
    continue = (e) => {
        e.preventDefault();
        if (this.props.state.formARate > 0) {
            this.props.nextStep();
        } else {
        }
    };

    render() {
        const handleChange = this.props.handleChange;

        const classes = this.props.values.classes;
        const values = this.props.values;

        return (
            <React.Fragment>
                <MuiThemeProvider>
                    <>
                        <AppBar title="Enter User Details" />
                        <CssBaseline />
                        <Card square className={classes.paper}>
                            <CardMedia
                                className={classes.media}
                                image={values.companyBackground}
                                title={values.companyName}
                            >
                                {values.employeePicture === "" ? (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        p={2}
                                    >
                                        <Avatar
                                            alt={values.companyName}
                                            src={values.companyLogo}
                                            className={classes.medium}
                                        />
                                    </Box>
                                ) : (
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        p={2}
                                    >
                                        <Avatar
                                            alt={values.employeeName}
                                            src={values.employeePicture}
                                            className={classes.medium}
                                        />
                                    </Box>
                                )}
                            </CardMedia>

                            <div align="center">
                                <Typography
                                    className={classes.text}
                                    variant="h5"
                                    align="center"
                                >
                                    ¿ Cómo calificas la atención brindada por{" "}
                                    {values.employeeName} ?
                                </Typography>
                            </div>
                            <div align="center" style={{ marginTop: 20 }}>
                                <Rating
                                    name="score"
                                    defaultValue={values.formARate}
                                    value={values.formARate}
                                    precision={1}
                                    size="large"
                                    onChange={handleChange("formARate")}
                                >
                                    {values.formARate !== null && (
                                        <Box ml={2}>{values.formARate}</Box>
                                    )}
                                </Rating>
                            </div>
                            <div align="center">
                                <TextField
                                    placeholder="Ingresa un comentario (Opcional)"
                                    label="Comentario"
                                    onChange={handleChange("formAComment")}
                                    defaultValue={values.formAComment}
                                    margin="normal"
                                    variant="outlined"
                                    rows="5"
                                    multiline
                                    fullWidth
                                    className={classes.paddinglr}
                                />
                            </div>
                            <div align="center">
                                <Button
                                    color="primary"
                                    size="large"
                                    variant="contained"
                                    style={{ marginTop: 40, marginBottom: 20 }}
                                    onClick={this.continue}
                                >
                                    Calificame
                                </Button>
                            </div>
                        </Card>
                    </>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

export default RateFormStepB;
