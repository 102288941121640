import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PhoneIcon from '@material-ui/icons/Phone';

import { i18n } from "../../translate/i18n";

import ContactModal from "../ContactModal";
import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { TextField } from "@material-ui/core";
import { Contacts } from "@material-ui/icons";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderTopRightRadius: 4,
		borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: "#eee",
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: "#eee",
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 160,
		height: 160,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
	fullBlock: {
		width: 320,
		padding: 20,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, orders, loading }) => {
	const classes = useStyles();
	const options = { month: "long", day: "numeric", year: "numeric" };
	const [modalOpen, setModalOpen] = useState(false);
	let firstorderindex = 0;
	var membersince = '';
	if (contact.orders) {
		if (contact.orders.length >= 1) {
			firstorderindex = contact.orders.length - 1;
			membersince = new Intl.DateTimeFormat("en-US", options).format(new Date(new Date(contact.orders[firstorderindex].shopifyCreatedAt).toISOString()));
		}
		
	}


	return (
		<Drawer
			className={classes.drawer}
			variant="persistent"
			anchor="right"
			open={open}
			PaperProps={{ style: { position: "absolute" } }}
			BackdropProps={{ style: { position: "absolute" } }}
			ModalProps={{
				container: document.getElementById("drawer-container"),
				style: { position: "absolute" },
			}}
			classes={{
				paper: classes.drawerPaper,
			}}
		>
			<div className={classes.header}>
				<IconButton onClick={handleDrawerClose}>
					<CloseIcon />
				</IconButton>
				<Typography style={{ justifySelf: "center" }}>
					{i18n.t("contactDrawer.header")}
				</Typography>
			</div>
			{loading ? (
				<ContactDrawerSkeleton classes={classes} />
			) : (
					<div className={classes.content}>
						<Paper square variant="outlined" className={classes.contactHeader}>
							<Avatar
								alt={contact.name}
								src={contact.profilePicUrl}
								className={classes.contactAvatar}
							></Avatar>

							<Typography variant="h4">{contact.name}</Typography>
							<Typography >
								<PhoneIcon fontSize="small" />&nbsp; &nbsp;<Link href={`tel:${contact.number}`} fontSize="medium">{contact.number}</Link>
							</Typography>

							{contact.orders.length > 0 ? <>

								<br />
								<Typography variant="h6" display="block" align="center" noWrap>{membersince}</Typography>
								<Typography variant="overline" display="block" align="center" noWrap>
									MIEMBRO DESDE
            				</Typography>

								<div className={classes.fullBlock}>
									<Grid container alignItems="center">
										<Grid item xs>
											<Typography variant="h6" display="block" align="center">
												${contact.orders[0].historicalTotalSpent}
											</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="h6" display="block" align="center">
												{contact.orders[0].ordersCount}
											</Typography>
										</Grid>
									</Grid>
									<Grid container alignItems="center">
										<Grid item xs>
											<Typography variant="overline" display="block" gutterBottom align="center" noWrap>
												LIFETIME VALUE
            							</Typography>
										</Grid>
										<Grid item xs>
											<Typography variant="overline" display="block" gutterBottom align="center" noWrap>
												TOTAl PEDIDOS
            							</Typography>
										</Grid>
									</Grid>

								</div>


								<div className={classes.fullBlock}>
									<Typography gutterBottom variant="body1">
										Ultimos pedidos
        						</Typography>
									<List>
										<>
											{
												contact.orders.map((co) => {
													const urlid = "https://le-pain-quotidien-argentina.myshopify.com/admin/orders/" + co.identifier;
													const fecha = new Date(co.shopifyUpdatedAt).toISOString();

													const date = new Date(fecha);
													const americanDate = new Intl.DateTimeFormat("en-US", options).format(date);
												
													return (
														<ListItem button component="a" href={urlid}>
															<ListItemText primary={co.name} secondary={americanDate} />
														</ListItem>
													)
												})}
										</>
									</List>
								</div>

								<div className={classes.fullBlock}>
									<Typography gutterBottom variant="body1">
										Etiquetas
        						</Typography>
									<div>
										<Chip className={classes.chip} label="comprador" />
										<Chip className={classes.chip} color="primary" label="recurrente" />

									</div>
								</div>

								<div className={classes.fullBlock}>
									<Typography gutterBottom variant="body1">
										Notas
        						</Typography>
									<div>
										<TextField
											id="outlined-multiline-static"
											multiline
											rows={4}
											placeholder="Deja notas o comentarios aqui"
											variant="outlined"
											fullWidth
										/>
									</div>
								</div>
							</> : (console.log("no tiene ordenes"))}

						</Paper>
						<Paper square variant="outlined" className={classes.contactDetails}>
							<ContactModal
								open={modalOpen}
								onClose={() => setModalOpen(false)}
								contactId={contact.id}
							></ContactModal>
							<Typography variant="subtitle1">
								{i18n.t("contactDrawer.extraInfo")}
							</Typography>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => setModalOpen(true)}
							>
								{i18n.t("contactDrawer.buttons.edit")}
							</Button>
							{contact?.extraInfo?.map(info => (
								<Paper
									key={info.id}
									square
									variant="outlined"
									className={classes.contactExtraInfo}
								>
									<InputLabel>{info.name}</InputLabel>
									<Typography component="div" noWrap style={{ paddingTop: 2 }}>
										<MarkdownWrapper>{info.value}</MarkdownWrapper>
									</Typography>
								</Paper>
							))}
						</Paper>
					</div>
				)}
		</Drawer>
	);
};

export default ContactDrawer;
