import React from "react";

import { Avatar, CardHeader, Chip } from "@material-ui/core";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { i18n } from "../../translate/i18n";

const TicketInfo = ({ contact, ticket, onClick }) => {
	console.log('Objecto whatsapp');
	console.log(ticket);
	let linename = (ticket.whatsapp?.name) ? ticket.whatsapp.name : '';
	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
			
			title={linename != '' ? (
				<><span style={{marginRight: '10px'}}>{contact.name} #{ticket.id}</span><Chip icon={<WhatsAppIcon />} color="default" label={linename}/></>
			):(
				<><span>{contact.name} #{ticket.id}</span></>
			)}
			subheader={
				ticket.user &&
				`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name}`
			}
		/>
	);
};

export default TicketInfo;
