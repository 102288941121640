import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Title from "../../components/Title";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from '@material-ui/icons/Edit';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

// import TicketsManager from "../../components/ContactsManager/";
// import Profile from "../../components/Profile/";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chatContainer: {
		flex: 1,
		// backgroundColor: "#eee",
		padding: theme.spacing(0),
		height: `calc(100% - 48px)`,
		overflowY: "hidden",
		marginTop: '10px'
	},

	chatPapper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
	},

	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
	},
	messagessWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	welcomeMsg: {
		backgroundColor: "#eee",
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "right",
		height: "100%",
		textAlign: "center",
	},
	profileBox: {
		display: "flex",
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		justifyContent: "space-evenly",
		height: "100%",
		textAlign: "center",
	},
	profileHeader: {
		backgroundColor: "#eee",
		// display: "flex",
		// justifyContent: "space-evenly",
		alignItems: "right",
		// height: "100%",
		// textAlign: "right",
	},
}));

const Profile = () => {
	const classes = useStyles();

	return (
		<div className={classes.chatContainer}>
			<div className={classes.chatPapper}>
				<Grid container spacing={0}>
					<Grid item xs={4} className={classes.contactsWrapper}>
						<Box />
					</Grid>
					<Grid item xs={8}>
							
							<Paper square elevation={0} className={classes.profileHeader}>

								<MainHeader>
								<Title>Profile Name</Title>
								<MainHeaderButtonsWrapper>
									<Button
									  variant="contained"
									  color="default"
									  style={{ float: "right", marginTop: '30px'}}
									  className={classes.button}
									  startIcon={<WhatsAppIcon />}
									>
									  Contactar
									</Button>

									<Button
									  variant="contained"
									  color="default"
									  style={{ float: "right", marginTop: '30px'}}
									  className={classes.button}
									  startIcon={<EditIcon />}
									>
									  Editar
									</Button>
								</MainHeaderButtonsWrapper>
								</MainHeader>
							</Paper>

							<Paper square elevation={0} className={classes.welcomeMsg}>
								<Grid item xs={12}>
								<Paper variant="outlined" className={classes.profileBox}>
									<p>{i18n.t("No hay ningún perfil seleccionado.")}</p>
								</Paper>
								</Grid>
							</Paper>

					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default Profile;
