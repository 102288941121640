import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { AuthContext } from "../../context/Auth/AuthContext";






import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
	Checkbox,
	Select, MenuItem, FormControl, Table, TableRow, TableHead, TableCell, TableBody,
	Accordion, AccordionSummary, AccordionDetails, Typography
} from "@material-ui/core";

import { ExpandMore } from '@material-ui/icons/';

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import CompanySelect from "../CompanySelect";

import TimezoneSelect from 'react-timezone-select'

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const { activeCompanyId } = useContext(AuthContext);

	const timeIntervals = [
		{ label: "12:00 AM", value: "00:00" },
		{ label: "12:15 AM", value: "00:15" },
		{ label: "12:30 AM", value: "00:30" },
		{ label: "12:45 AM", value: "00:45" },
		{ label: "1:00 AM", value: "01:00" },
		{ label: "1:15 AM", value: "01:15" },
		{ label: "1:30 AM", value: "01:30" },
		{ label: "1:45 AM", value: "01:45" },
		{ label: "2:00 AM", value: "02:00" },
		{ label: "2:15 AM", value: "02:15" },
		{ label: "2:30 AM", value: "02:30" },
		{ label: "2:45 AM", value: "02:45" },
		{ label: "3:00 AM", value: "03:00" },
		{ label: "3:15 AM", value: "03:15" },
		{ label: "3:30 AM", value: "03:30" },
		{ label: "3:45 AM", value: "03:45" },
		{ label: "4:00 AM", value: "04:00" },
		{ label: "4:15 AM", value: "04:15" },
		{ label: "4:30 AM", value: "04:30" },
		{ label: "4:45 AM", value: "04:45" },
		{ label: "5:00 AM", value: "05:00" },
		{ label: "5:15 AM", value: "05:15" },
		{ label: "5:30 AM", value: "05:30" },
		{ label: "5:45 AM", value: "05:45" },
		{ label: "6:00 AM", value: "06:00" },
		{ label: "6:15 AM", value: "06:15" },
		{ label: "6:30 AM", value: "06:30" },
		{ label: "6:45 AM", value: "06:45" },
		{ label: "7:00 AM", value: "07:00" },
		{ label: "7:15 AM", value: "07:15" },
		{ label: "7:30 AM", value: "07:30" },
		{ label: "7:45 AM", value: "07:45" },
		{ label: "8:00 AM", value: "08:00" },
		{ label: "8:15 AM", value: "08:15" },
		{ label: "8:30 AM", value: "08:30" },
		{ label: "8:45 AM", value: "08:45" },
		{ label: "9:00 AM", value: "09:00" },
		{ label: "9:15 AM", value: "09:15" },
		{ label: "9:30 AM", value: "09:30" },
		{ label: "9:45 AM", value: "09:45" },
		{ label: "10:00 AM", value: "10:00" },
		{ label: "10:15 AM", value: "10:15" },
		{ label: "10:30 AM", value: "10:30" },
		{ label: "10:45 AM", value: "10:45" },
		{ label: "11:00 AM", value: "11:00" },
		{ label: "11:15 AM", value: "11:15" },
		{ label: "11:30 AM", value: "11:30" },
		{ label: "11:45 AM", value: "11:45" },
		{ label: "12:00 PM", value: "12:00" },
		{ label: "12:15 PM", value: "12:15" },
		{ label: "12:30 PM", value: "12:30" },
		{ label: "12:45 PM", value: "12:45" },
		{ label: "1:00 PM", value: "13:00" },
		{ label: "1:15 PM", value: "13:15" },
		{ label: "1:30 PM", value: "13:30" },
		{ label: "1:45 PM", value: "13:45" },
		{ label: "2:00 PM", value: "14:00" },
		{ label: "2:15 PM", value: "14:15" },
		{ label: "2:30 PM", value: "14:30" },
		{ label: "2:45 PM", value: "14:45" },
		{ label: "3:00 PM", value: "15:00" },
		{ label: "3:15 PM", value: "15:15" },
		{ label: "3:30 PM", value: "15:30" },
		{ label: "3:45 PM", value: "15:45" },
		{ label: "4:00 PM", value: "16:00" },
		{ label: "4:15 PM", value: "16:15" },
		{ label: "4:30 PM", value: "16:30" },
		{ label: "4:45 PM", value: "16:45" },
		{ label: "5:00 PM", value: "17:00" },
		{ label: "5:15 PM", value: "17:15" },
		{ label: "5:30 PM", value: "17:30" },
		{ label: "5:45 PM", value: "17:45" },
		{ label: "6:00 PM", value: "18:00" },
		{ label: "6:15 PM", value: "18:15" },
		{ label: "6:30 PM", value: "18:30" },
		{ label: "6:45 PM", value: "18:45" },
		{ label: "7:00 PM", value: "19:00" },
		{ label: "7:15 PM", value: "19:15" },
		{ label: "7:30 PM", value: "19:30" },
		{ label: "7:45 PM", value: "19:45" },
		{ label: "8:00 PM", value: "20:00" },
		{ label: "8:15 PM", value: "20:15" },
		{ label: "8:30 PM", value: "20:30" },
		{ label: "8:45 PM", value: "20:45" },
		{ label: "9:00 PM", value: "21:00" },
		{ label: "9:15 PM", value: "21:15" },
		{ label: "9:30 PM", value: "21:30" },
		{ label: "9:45 PM", value: "21:45" },
		{ label: "10:00 PM", value: "22:00" },
		{ label: "10:15 PM", value: "22:15" },
		{ label: "10:30 PM", value: "22:30" },
		{ label: "10:45 PM", value: "22:45" },
		{ label: "11:00 PM", value: "23:00" },
		{ label: "11:15 PM", value: "23:15" },
		{ label: "11:30 PM", value: "23:30" },
		{ label: "11:45 PM", value: "23:45" },
	];

	const initialWorkingHours = {
		'monday': { to: "18:00", from: "09:00", enabled: false },
		'tuesday': { to: "18:00", from: "09:00", enabled: false },
		'wednesday': { to: "18:00", from: "09:00", enabled: false },
		'thursday': { to: "18:00", from: "09:00", enabled: false },
		'friday': { to: "18:00", from: "09:00", enabled: false },
		'saturday': { to: "18:00", from: "09:00", enabled: false },
		'sunday': { to: "18:00", from: "09:00", enabled: false }
	};
	const [workingHours, setWorkingHours] = useState(initialWorkingHours);
	const [selectedTimezone, setSelectedTimezone] = useState('');

	const initialState = {
		name: "",
		greetingMessage: "",
		isDefault: false,
		workingHours: workingHours,
		outofofficeMessage: '',
		timezone: selectedTimezone
	};

	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);






	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				console.log('Volviendo del get');
				console.log(data);
				setWhatsApp(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);

				const whatsCompanyIds = data.companies?.map(company => company.id);
				setSelectedCompanyIds(whatsCompanyIds);

				const workingHoursObj = (data.workingHours !== null) ? JSON.parse(data.workingHours) : initialWorkingHours;
				setWorkingHours(workingHoursObj);

				//setSelectedTimezone(data.timezone);

			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		const whatsappData = { ...values, queueIds: selectedQueueIds, companyIds: [activeCompanyId], companyId: activeCompanyId, workingHours: workingHours };
		console.log("enviando data");
		console.log(whatsappData);
		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
	};

	const setClickWorkingHours = (day) => {
		let myworkingtmp = (workingHours !== null) ? workingHours : initialWorkingHours;
		myworkingtmp[day].enabled = !myworkingtmp[day].enabled;
		setWorkingHours({ ...myworkingtmp });
	};

	const handlePickupChangeTimeSlot = (arr, key, value) => {
		console.log(value.target.value);
		let myworkingtmp = (workingHours !== null) ? workingHours : initialWorkingHours;
		myworkingtmp[arr][key] = value.target.value;
		console.log(myworkingtmp);
		setWorkingHours({ ...myworkingtmp });
	};

	const handleChangeTimezone = (val) => {
		setWhatsApp({...whatsApp, timezone: val.value})
		setSelectedTimezone(val.value);
	}






	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label={i18n.t("whatsappModal.form.default")}
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										rows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div style={{width:'100%', paddingTop: 10, paddingBottom: 10}}>
									<QueueSelect
										selectedQueueIds={selectedQueueIds}
										onChange={selectedIds => setSelectedQueueIds(selectedIds)}
									/>
								</div>

								<div style={{width:'100%',  paddingBottom: 10}}>
									<span>Timezone</span>
									<TimezoneSelect
											name="timezone"
											value={values.timezone ? values.timezone : ''}
											style={{width:'100%', zIndex: 1001}}
											onChange={handleChangeTimezone}
										/>
								</div>
								
								<div style={{width:'100%', paddingBottom: 10}}>
									<div style={{textAlign: 'center', width:'100%', paddingBottom: 10}}>🤖 Automatic response configuration 🤖</div>
									<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Typography>Working hours:</Typography>
									</AccordionSummary>
									<AccordionDetails>



										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell align="center">
														Available Days
											</TableCell>
													<TableCell align="center">
														From:
											</TableCell>
													<TableCell align="center">
														To:
											</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{Object.keys(workingHours).map(function (key) {
													return (
														<TableRow>
															<TableCell align="left">
																<FormControlLabel
																	control={
																		<Field
																			as={Checkbox}
																			color="primary"
																			name={`values.workingHours.{${key}}.enabled`}
																			checked={workingHours[key].enabled}
																			onClick={() => setClickWorkingHours(key)}
																		/>

																	}
																	label={i18n.t(`whatsappModal.form.workinghours.${key}`)}
																/>
															</TableCell>
															<TableCell align="center">
																<FormControl >
																	<Select
																		key={`workingHours_${key}_from`}

																		onChange={(e) =>
																			handlePickupChangeTimeSlot(
																				`${key}`,
																				"from",
																				e
																			)
																		}
																		value={
																			workingHours[key].from
																		}
																	>
																		{timeIntervals.map(function (element) {
																			return (
																				<MenuItem value={element.value}>{element.label}</MenuItem>
																			)
																		})}

																	</Select>
																</FormControl>
															</TableCell>
															<TableCell align="center">
																<FormControl >
																	<Select
																		key={`workingHours_${key}_to`}

																		onChange={(e) =>
																			handlePickupChangeTimeSlot(
																				`${key}`,
																				"to",
																				e
																			)
																		}
																		value={
																			workingHours[key].to
																		}

																	>

																		{timeIntervals.filter(
																			(a) =>
																				a.value >
																				workingHours[key].from
																		).map(function (element) {
																			return (
																				<MenuItem value={element.value}>{element.value}</MenuItem>
																			)
																		})}


																	</Select>
																</FormControl>
															</TableCell>
														</TableRow>
													)
												})}

											</TableBody>
										</Table>
									</AccordionDetails>
								</Accordion>
								<Accordion>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										aria-controls="panel2a-content"
										id="panel2a-header"
									>
										<Typography>Offline greeting message</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<div style={{ width: '100%' }}>
											<Field
												as={TextField}
												label={'Offline greeting message'}
												type="outofofficeMessage"
												multiline
												rows={5}
												fullWidth
												name="outofofficeMessage"
												error={
													touched.outofofficeMessage && Boolean(errors.outofofficeMessage)
												}
												helperText={
													touched.outofofficeMessage && errors.outofofficeMessage
												}
												variant="outlined"
												margin="dense"
											/>
										</div>
									</AccordionDetails>
								</Accordion>
								</div>
								

							
								{console.log('Refreshing Working Hours')}
								{console.log(workingHours)}












							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
