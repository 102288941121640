import React, { useEffect, useReducer, useState, useContext } from "react";

import openSocket from "socket.io-client";

import {
	Button,
	IconButton,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,

} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import ReviewModal from "../../components/ReviewModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import Rating from '@material-ui/lab/Rating';

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	mainPaper: {
		flex: 1,
		padding: theme.spacing(1),
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},
	customTableCell: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
}));



const reducer = (state, action) => {
	if (action.type === "LOAD_REVIEWS") {
		const reviews = action.payload.reviews;

		const newReviews = [];

		reviews.forEach(review => {
			const reviewIndex = state.findIndex(q => q.id === review.id);

			newReviews.push(review);
			
		});


		return [...newReviews];
	}

	if (action.type === "UPDATE_REVIEWS") {
		const review = action.payload.review;
		const reviewIndex = state.findIndex(u => u.id === review.id);
		if (reviewIndex !== -1) {
			state[reviewIndex] = review;
			return [...state];
		} else {
			return [review, ...state];
		}
	}

	if (action.type === "DELETE_REVIEWS") {
		const reviewId = action.payload.reviewid;
		const reviewIndex = state.findIndex(q => q.id === reviewId);
		if (reviewIndex !== -1) {
			state.splice(reviewIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const Reviews = () => {
	const classes = useStyles();

	const [reviews, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(false);

	const [reviewModalOpen, setReviewModalOpen] = useState(false);
	const [selectedReview, setSelectedReview] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	const {activeCompanyId} = useContext(AuthContext);


	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const { data } = await api.get("/reviews?companyIds[0]=" + activeCompanyId);
				dispatch({ type: "LOAD_REVIEWS", payload: data });
				setLoading(false);
			} catch (err) {
				toastError(err);
				setLoading(false);
			}
		})();
	}, [activeCompanyId]);



	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("review", data => {
			if (data.action === "update" || data.action === "create") {
				dispatch({ type: "UPDATE_REVIEWS", payload: data.review });
			}

			if (data.action === "delete") {
				dispatch({ type: "DELETE_REVIEWS", payload: data.reviewId });
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [activeCompanyId]);

	const handleOpenReviewModal = () => {
		setReviewModalOpen(true);
		setSelectedReview(null);
	};

	const handleCloseReviewModal = () => {
		setReviewModalOpen(false);
		setSelectedReview(null);
	};

	const handleEditReview = review => {
		setSelectedReview(review);
		setReviewModalOpen(true);
	};

	const handleCloseConfirmationModal = () => {
		setConfirmModalOpen(false);
		setSelectedReview(null);
	};

	const handleDeleteReview = async reviewId => {
		try {
			await api.delete(`/reviews/${reviewId}`);
			toast.success(i18n.t("Review deleted successfully!"));
		} catch (err) {
			toastError(err);
		}
		setSelectedReview(null);
	};

	return (
		<MainContainer>
			<ConfirmationModal
				title={
					selectedReview &&
					`${i18n.t("reviews.confirmationModal.deleteTitle")} ${
					selectedReview.name
					}?`
				}
				open={confirmModalOpen}
				onClose={handleCloseConfirmationModal}
				onConfirm={() => handleDeleteReview(selectedReview.id)}
			>
				{i18n.t("reviews.confirmationModal.deleteMessage")}
			</ConfirmationModal>
			<ReviewModal
				open={reviewModalOpen}
				onClose={handleCloseReviewModal}
				reviewId={selectedReview?.id}
			/>
			<MainHeader>
				<Title>{i18n.t("reviews.title")}</Title>
				
			</MainHeader>
			<Paper className={classes.mainPaper} variant="outlined">
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell align="center">
								{i18n.t("reviews.table.client")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("reviews.table.review")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("reviews.table.score")}
							</TableCell>
							<TableCell align="center">
								{i18n.t("reviews.table.employee")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<>
							{reviews.map(review => (
								<TableRow key={review.id}>
									<TableCell align="center">{review.contact.name}</TableCell>
									<TableCell align="center">{review.review}</TableCell>
									<TableCell align="center">
										<Rating
											readOnly
											value={review.score}

											size="small"

										/>

									</TableCell>
									<TableCell align="center">
										{review.user.name}
									</TableCell>

									
								</TableRow>
							))}
							{loading && <TableRowSkeleton columns={4} />}
						</>
					</TableBody>
				</Table>
			</Paper>
		</MainContainer>
	);
};

export default Reviews;
