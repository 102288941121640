import { useState, useEffect, useContext } from "react";
import toastError from "../../errors/toastError";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";


const useContacts = ({
	searchParam,
	pageNumber,
	status,
	date,
	showAll,
	queueIds,
	withUnreadMessages,
	fromdate,
	todate
}) => {
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(false);
	const [contacts, setContacts] = useState([]);

	const {activeCompanyId} = useContext(AuthContext);


	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchContacts = async () => {
				try {
					const { data } = await api.get("/contacts?companyIds[0]=" + activeCompanyId, {
						params: {
							searchParam,
							pageNumber,
							status,
							date,
							showAll,
							queueIds,
							withUnreadMessages,
							fromdate,
							todate,
						},
					});
					setContacts(data.contacts);
					setHasMore(data.hasMore);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};
			fetchContacts();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [
		searchParam,
		pageNumber,
		status,
		date,
		showAll,
		queueIds,
		withUnreadMessages,
		fromdate,
		todate,
		activeCompanyId
	]);

	return { contacts, loading, hasMore };
};

export default useContacts;
