import React, { useState, useEffect, useRef, useContext} from "react";
import { useTheme } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	Legend,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

import { startOfHour, parseISO, format } from "date-fns";

import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useContacts from "../../hooks/useContacts";
import { AuthContext } from "../../context/Auth/AuthContext";

const ChartSummaryConversations = ({ fromdate, todate }) => {
	const theme = useTheme();

	const {activeCompanyId} = useContext(AuthContext);


	const date = useRef(new Date().toISOString());
	const { contacts } = useContacts({ fromdate: fromdate, todate: todate });


	return (
		<React.Fragment>
			<Title>Contactos Generados</Title>
			<ResponsiveContainer>
				<Card sx={{ maxWidth: 345 }}>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div">
							{contacts.length}
        				</Typography>
					</CardContent>
				</Card>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default ChartSummaryConversations;
