import React, { Component } from "react";
import RateFormStepA from "./RateFormStepA";
import RateFormStepB from "./RateFormStepB";
import RateFormStepC from "./RateFormStepC";
import RateFormStepD from "./RateFormStepD";
import RateFormFinal from "./RateFormFinal";
import RateFormSucess from "./RateFormSucess";
import api from "../../services/api";

import { TabletAndroidSharp } from "@material-ui/icons";
import { validate } from "@material-ui/pickers";

export class RateForm extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidateForm = this.handleValidateForm.bind(this);
    }
    state = {
        step: 1,
        firstName: "",
        email: "",
        phone: "",
        formARate: 0,
        formAComment: "",
        formBRate: 0,
        formBComment: "",
        formCRate: 0,
        formCComment: "",
        willPayTip: false,
        employeeId: this.props.val.employeeId,
        companyId: this.props.val.companyId,
        errors: {
            formAComment: "",
            formBComment: "",
            formCComment: "",
            firstName: "",
            email: "",
            phone: "",
        },
        touched_firstName: false,
        touched_email: false,
        touched_phone: false,
        touched_formAComment: false,
        touched_formBComment: false,
        touched_formCComment: false,
        error_firstName: "",
    };

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({
            step: step + 1,
        });
    };

    // Go back to prev step
    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    };

    // Handle fields change
    handleChange = (input) => (e) => {
        if (input === "willPayTip") {
            this.setState({ [input]: e.target.checked });
        } else if (input === "phone") {
            this.setState({ [input]: e });
        } else {
            this.setState({ [input]: e.target.value });
        }
    };

    handleTouch = (input) => (e) => {
        if (e.target.value !== "") {
            this.setState({ [input]: true });
        } else {
            this.setState({ [input]: false });
        }
    };

    validateForm() {
        //validamos lo principal
        //nombre y apellido

        /*if (this.state.firstName === "") {
            this.setState({ error_firstName: "Este campo es obligatorio" });
            this.setState({ touched_firstName: true });
            return false;
        }

        if (!this.state.email) {
            this.setState({ error_email: "Este campo es obligatorio" });
            this.setState({ touched_email: true });
            return false;
        } else if (
            !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
                this.state.email
            )
        ) {
            this.setState({ error_email: "Email invalido" });
            this.setState({ touched_email: true });
            return false;
        } else {
            this.setState({ error_email: "" });
            this.setState({ touched_email: false });
        }*/

        return true;
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.validateForm()) {
            try {
                const data = api.post("/rate/", this.state);
                if (data !== undefined) {
                    this.nextStep();
                }
            } catch (error) {
                throw new Error(error);
            }
        } else {
        }
    }

    handleValidateForm(event) {
        event.preventDefault();
        if (this.validateForm()) {
            this.nextStep();
        } else {
        }
    }

    render() {
        const { step } = this.state;
        const { firstName, email, occupation, city, bio } = this.state;
        const values = this.props;

        switch (step) {
            case 1:
                return (
                    <RateFormStepA
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values.val}
                        state={this.state}
                        touched={this.state.touched}
                        handleTouch={this.handleTouch}
                    />
                );
            case 2:
                return (
                    <RateFormStepB
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values.val}
                        state={this.state}
                        touched={this.touched}
                        handleTouch={this.handleTouch}
                    />
                );
            case 3:
                return (
                    <RateFormStepC
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values.val}
                        state={this.state}
                        touched={this.touched}
                        handleTouch={this.handleTouch}
                    />
                );
            /*case 4:
                return (
                    <RateFormStepD
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange={this.handleChange}
                        values={values.val}
                        state={this.state}
                        touched={this.touched}
                        handleTouch={this.handleTouch}
                    />
                );*/
            case 4:
                return (
                    <RateFormFinal
                        state={this.state}
                        values={values.val}
                        handleChange={this.handleChange}
                        handleTouch={this.handleTouch}
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        touched={this.touched}
                        setState={this.setState}
                        handleSubmit={this.handleSubmit}
                    />
                );

            case 99:
                return (
                    <RateFormSucess
                        values={values.val}
                        nextStep={this.nextStep}
                        handleChange={this.handleChange}
                        values={values.val}
                        state={this.state}
                        touched={this.state.touched}
                        handleTouch={this.handleTouch}
                        handleSubmit={this.handleSubmit}
                    />
                );

            case 5:
                window.location.href = values.val.redirectUrl;
            default:
                console.log("this is a RateForm");
        }
    }
}

export default RateForm;
