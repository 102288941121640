import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, useRouteMatch } from 'react-router-dom';
import QRCode from "qrcode.react";
import toastError from "../../errors/toastError";
import { Grid, Dialog, DialogContent, Paper, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	button: {
	  margin: theme.spacing(1),
	},
  }));


const QrcodeModalGenerator = ({ open, onClose, userId, companyId }) => {

	const classes = useStyles();
	const [qrCode, setQrCode] = useState(window.location.href.replace('/users', `/rate/${companyId}/${userId}`));


	const downloadQRCode = () => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById("UID-" + userId);
		const pngUrl = canvas
			.toDataURL("image/png")
			.replace("image/png", "image/octet-stream");
		let downloadLink = document.createElement("a");
		downloadLink.href = pngUrl;
		downloadLink.download = `UID-${userId}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};



	return (
		<Dialog open={open} onClose={onClose} maxWidth="lg" scroll="paper">
			<DialogContent>
				<Paper elevation={0}>

					{qrCode ? (
						<>
							<Grid>
								<QRCode id={`UID-${userId}`} value={window.location.href.replace('/users', `/rate/${companyId}/${userId}`)} size={256} />
								{/* Use html attributes to pass in any required data to the ref node. Note: target: '_blank' was required in order to initiate the download dialogue */}
							</Grid>
							<Grid style={{
								paddingTop: '20px',
								textAlign: 'center'
							}}>
								


								<Button
									variant="contained"
									color="default"
									className={classes.button}
									startIcon={<CloudDownloadIcon />}
									onClick={downloadQRCode}
								>
									Descargar
      </Button>
							</Grid>
						</>
					) : (
							<span>Waiting for QR Code</span>
						)}
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default React.memo(QrcodeModalGenerator);
