import React, { useContext } from "react";
import { Route as RouterRoute, Redirect } from "react-router-dom";

import { AuthContext } from "../context/Auth/AuthContext";
import BackdropLoading from "../components/BackdropLoading";

const Route = ({ component: Component, isPrivate = false, ...rest }) => {
    const { isAuth, loading } = useContext(AuthContext);

    console.log(Component);

    if (!isAuth && isPrivate) {
        return (
            <>
                {loading && <BackdropLoading />}
                <Redirect
                    to={{ pathname: "/login", state: { from: rest.location } }}
                />
            </>
        );
    }
    console.log("ENTRANDO A ROUTE");
    console.log(isAuth);
    console.log(isPrivate);
    console.log(rest);

    if (isAuth && !isPrivate) {
        if (rest.path == "/rate/:companyId/:employeeId") {
            return (
                <>
                    {loading && <BackdropLoading />}
                    <RouterRoute {...rest} component={Component} />
                </>
            );
        } else {
            return (
                <>
                    {loading && <BackdropLoading />}
                    <Redirect
                        to={{ pathname: "/", state: { from: rest.location } }}
                    />
                    ;
                </>
            );
        }
    }

    return (
        <>
            {loading && <BackdropLoading />}
            <RouterRoute {...rest} component={Component} />
        </>
    );
};

export default Route;
