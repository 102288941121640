import React, { useState } from "react";
import Box from '@material-ui/core/Box';
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TextField from '@material-ui/core/TextField';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker,
	KeyboardDatePicker,
	DatePicker
} from '@material-ui/pickers';

import Menu from '@material-ui/core/Menu';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ChartTotalSales from "./ChartTotalSales";
import ChartTotalConversations from "./ChartTotalConversations";
import ChartSummaryConversations from "./ChartSummaryConversations"

const options = [
	'Hoy',
	'Ultimos 7 dias',
	'Este mes',
	'Este trimestre',
	'Este año'
];

var today = new Date();
var sevendays = new Date();
sevendays.setDate(today.getDate() - 7);
var firstDayofMonth = new Date(today.getFullYear(), today.getMonth(), 1);
var firstDayofYear = new Date(today.getFullYear(), 0, 1);
var firstDatofQuarter = new Date(today.getFullYear(), today.getMonth() - 3, 1);

const dateranges = [
	{
		from: today, to: today
	}, {
		from: sevendays, to: today
	}, {
		from: firstDayofMonth, to: today
	}, {
		from: firstDatofQuarter, to: today
	},
	{
		from: firstDayofYear, to: today
	}

]

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(theme => ({
	container: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: 350,
	},
}));

const Dashboard = () => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const [fromdate, setFromDate] = React.useState(new Date());
	const [todate, setToDate] = React.useState(new Date());
	const open = Boolean(anchorEl);

	const handleClick = (event) => {

		setAnchorEl(event.currentTarget);
		//console.log("Seteando Index");
		//console.log(index);
		//setSelectedIndex(index);
	};

	const handleClose = (event, index) => {

		console.log(event.currentTarget.index);
		console.log(event.currentTarget.dataset);

		setSelectedIndex(event.currentTarget.dataset.index);
		setAnchorEl(null);
	};
	return (
		<div>
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<Grid item xs={12} style={{ display: "flex" }}>
					<Typography variant="h5" style={{ marginTop: "20px", float: 'left' }}>
								Ventas
					     </Typography>
						<div style={{ width: '100%' }}>
							<Box flexDirection="row">
							{<List component="nav" aria-label="main mailbox folders">
								<ListItem button>

									<ListItemText primary={options[selectedIndex]} onClick={handleClick} />
									<ListItemIcon>
										<IconButton
											aria-label="more"
											aria-controls="long-menu"
											aria-haspopup="true"
											onClick={handleClick}

										>
											<DateRangeIcon />
										</IconButton>
									</ListItemIcon>
								</ListItem>
							</List>}

								{<Menu
									id="long-menu"
									anchorEl={anchorEl}
									keepMounted
									open={open}
									onClose={handleClose}
									PaperProps={{
										style: {
											maxHeight: ITEM_HEIGHT * 4.5,
											width: '20ch',
										},
									}}
								>
									{options.map((option, index) => (
										<MenuItem data-index={index} key={option} selected={option === anchorEl} onClick={handleClose}>
											{option}
										</MenuItem>
									))}
								</Menu>
								}
							</Box>

							

						</div>

					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper} variant="outlined">
							<ChartTotalSales fromdate={dateranges[selectedIndex].from} todate={dateranges[selectedIndex].to} />
						</Paper>

						<Typography variant="h5" style={{ marginTop: "40px" }}>
							Conversaciones
						     </Typography>

					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper} variant="outlined">
							<ChartSummaryConversations fromdate={dateranges[selectedIndex].from} todate={dateranges[selectedIndex].to} />
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper className={classes.fixedHeightPaper} variant="outlined">
							<ChartTotalConversations fromdate={dateranges[selectedIndex].from} todate={dateranges[selectedIndex].to} />
						</Paper>
					</Grid>

					<Grid item xs={12} style={{ marginTop: '20px' }}>
						<div style={{ textAlign: 'right', color: '#aaaaaa', fontFamily: 'Nunito', fontSize: '13px' }}>Copyright 2020 © Unicollect. Todos los derechos reservados.</div>
					</Grid>

				</Grid>
			</Container>
		</div>
	);
};

export default Dashboard;
