import React, { useState, useEffect, useRef, useContext } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	Legend,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";

import { i18n } from "../../translate/i18n";

import Title from "./Title";
import useTickets from "../../hooks/useTickets";
import { AuthContext } from "../../context/Auth/AuthContext";


const ChartTotalConversations = ({ fromdate, todate}) => {
	const theme = useTheme();

	const date = useRef(new Date().toISOString());
	const {activeCompanyId} = useContext(AuthContext);
	const { tickets } = useTickets({ fromdate: fromdate, todate: todate  });

	const original = [
		{ time: "00:00", amount: 0, closed: 0, opened: 0 },
		{ time: "01:00", amount: 0, closed: 0, opened: 0 },
		{ time: "02:00", amount: 0, closed: 0, opened: 0 },
		{ time: "03:00", amount: 0, closed: 0, opened: 0 },
		{ time: "04:00", amount: 0, closed: 0, opened: 0 },
		{ time: "05:00", amount: 0, closed: 0, opened: 0 },
		{ time: "06:00", amount: 0, closed: 0, opened: 0 },
		{ time: "07:00", amount: 0, closed: 0, opened: 0 },
		{ time: "08:00", amount: 0, closed: 0, opened: 0 },
		{ time: "09:00", amount: 0, closed: 0, opened: 0 },
		{ time: "10:00", amount: 0, closed: 0, opened: 0 },
		{ time: "11:00", amount: 0, closed: 0, opened: 0 },
		{ time: "12:00", amount: 0, closed: 0, opened: 0 },
		{ time: "13:00", amount: 0, closed: 0, opened: 0 },
		{ time: "14:00", amount: 0, closed: 0, opened: 0 },
		{ time: "15:00", amount: 0, closed: 0, opened: 0 },
		{ time: "16:00", amount: 0, closed: 0, opened: 0 },
		{ time: "17:00", amount: 0, closed: 0, opened: 0 },
		{ time: "18:00", amount: 0, closed: 0, opened: 0 },
		{ time: "19:00", amount: 0, closed: 0, opened: 0 },
		{ time: "20:00", amount: 0, closed: 0, opened: 0 },
		{ time: "21:00", amount: 0, closed: 0, opened: 0 },
		{ time: "22:00", amount: 0, closed: 0, opened: 0 },
		{ time: "23:00", amount: 0, closed: 0, opened: 0 },
	];

	const [chartData, setChartData] = useState(original);



	useEffect(() => {
		
		setChartData(prevState => {
			let aux = [...original];
			aux.forEach(a => {
				if (tickets.length == 0){
					a.amount = 0;
					a.opened = 0;
					a.closed = 0;
				}else{
					tickets.forEach(ticket => {
						//console.log(ticket);
						if (format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time) {
							if (ticket.status === 'open'){
								a.opened++;
							}else{
								a.closed++;
							}
							//format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
							a.amount++;
						}
					});
				}
			});
			return aux;
		});
	}, [tickets, activeCompanyId]);
	
	console.log(chartData);

	return (
		<React.Fragment>
			<Title>Iniciadas vs Resueltas</Title>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={40}
					width={730}
					height={350}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 0,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
					</YAxis>
					<Tooltip />
					<Legend />
					<Bar dataKey="closed" stackId="a"  fill={theme.palette.primary.main} />
					<Bar dataKey="opened" stackId="a" fill="#8884d8" />
					
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default ChartTotalConversations;
