import { useState, useContext, useReducer, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";





import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";



import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Rating from "@material-ui/lab/Rating";
import Box from '@material-ui/core/Box';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import StarRateIcon from '@material-ui/icons/StarRate';
import MoreIcon from '@material-ui/icons/MoreVert';
import { toast } from "react-toastify";

import toastError from "../../errors/toastError";
import api from "../../services/api";

import RateForm from "../../components/RateForm/RateForm"


const useStyles = makeStyles((theme) => ({
	text: {
		padding: theme.spacing(2, 2, 0),
	},
	avatar:{
		marginTop: 20,
	},
	paper: {
		paddingBottom: 0,
	},
	list: {
		marginBottom: theme.spacing(2),
	},
	subheader: {
		backgroundColor: theme.palette.background.paper,
	},
	appBar: {
		top: 'auto',
		bottom: 0,
	},
	grow: {
		flexGrow: 1,
	},
	fabButton: {
		position: 'absolute',
		zIndex: 1,
		top: -30,
		left: 0,
		right: 0,
		margin: '0 auto',
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
	},
	large: {
		width: theme.spacing(20),
		height: theme.spacing(20),
	},
	medium: {
		width: theme.spacing(10),
		height: theme.spacing(10),
	},
	button:{
		marginTop: 20,
		marginBottom: 20
	},
	paddinglr: {
		marginLeft: 10,
		paddingRight: 20
	},
  	media: {
    	height: 120,
	  },
	pos: {
		marginBottom: 12,
	},
}));

const reducer = (state, action) => {
	if (action.type === "LOAD_RATE_DATA") {
		const reviews = action.payload;
		//console.log("Reviews");
		//console.log(reviews);
		return reviews;
	}

	if (action.type === "RESET") {
		return [];
	}
};

export default function Rate() {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [reviews, dispatch] = useReducer(reducer, []);
	const {companyId, employeeId} = useParams(0);
	const [nombre, setNombre] = useState("");
	const [cantidad, setCantidad] = useState(0);
	const [promedio, setPromedio] = useState(0);
	const [companyName, setCompanyName] = useState("");
	const [employeePicture, setEmployeePicture] = useState("");
	const [companyBackground, setCompanyBackground] = useState("");
	const [companyLogo, setCompanyLogo] = useState("");
	const [sucessMessage, setSucessMessage] = useState("");
	const [finalMessage, setFinalMessage] = useState("");
	const [redirectUrl, setRedirectUrl] = useState("");
	

	useEffect(() => {
		(async () => {
			setLoading(true);
			try {
				const { data } = await api.get("/rate/" + companyId + "/" + employeeId);
				//dispatch({ type: "LOAD_RATE_DATA", payload: data });
				//console.log(data);
				setNombre(data.name);
				setEmployeePicture(data.profilePicture);
				//console.log(parseFloat(data['reviews.promedio']));
				setCantidad(parseInt(parseInt(data['reviews.cantidad'])));
				setPromedio(parseFloat(data['reviews.promedio']));
				setCompanyName(data['companies.companyName']);
				setCompanyBackground(data['companies.background']);
				setCompanyLogo(data['companies.logo']);
				setSucessMessage(data['companies.ratingSucessMessage']);
				setFinalMessage(data['companies.ratingFinalMessage']);
				setRedirectUrl(data['companies.ratingRedirectUrl']);
				//const [nombre, setNombre] = useState(reviews.name);
				//const [cantidad, setCantidad] = useState(parseInt(reviews['reviews.cantidad']));
				//const [promedio, setPromedio] = useState(parseInt(reviews['reviews.promedio']));
				setLoading(false);
			} catch (err) {
				toastError(err);
				setLoading(false);
			}
		})();
	}, []);


	const handleSubmit = (vals) =>
	async (vals) => {
	  try {
		const { data } = await api.post("/rate/", vals);
	  } catch (error) {
		throw new Error(error);
	  }
	};

	

	return (
		<RateForm
		val={{
			employeeName: nombre, 
			employeeReviews: cantidad,
			employeeRate: promedio,
			employeePicture: employeePicture,
			companyId: companyId,
			companyName: companyName,
			companyLogo: companyLogo,
			companyBackground: companyBackground,
			sucessMessage: sucessMessage,
			finalMessage: finalMessage,
			redirectUrl: redirectUrl,
			employeeId: employeeId,
			classes: classes,

			
		}}
		handleSubmit={handleSubmit}
		/>
		
	);
}

