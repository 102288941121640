import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useContext,
} from "react";
import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import UserModal from "../../components/UserModal";
import { Modal } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";

import QrcodeModalGenerator from "../../components/QRCode/QrcodeModalGenerator";
import RateForm from "../../components/RateForm/RateForm";

const reducer = (state, action) => {
    if (action.type === "LOAD_USERS") {
        const users = action.payload;
        const newUsers = [];

        users.forEach((user) => {
            const userIndex = state.findIndex((u) => u.id === user.id);
            if (userIndex !== -1) {
                state[userIndex] = user;
            } else {
                newUsers.push(user);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_USERS") {
        const user = action.payload;
        const userIndex = state.findIndex((u) => u.id === user.id);

        if (userIndex !== -1) {
            state[userIndex] = user;
            return [...state];
        } else {
            return [user, ...state];
        }
    }

    if (action.type === "DELETE_USER") {
        const userId = action.payload;

        const userIndex = state.findIndex((u) => u.id === userId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const Servers = () => {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [activeCompanySelected, setActiveCompanySelected] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [userModalOpen, setUserModalOpen] = useState(false);

    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [users, dispatch] = useReducer(reducer, []);

    const [qrModalOpen, setQrModalOpen] = useState(false);
    const { activeCompanyId } = useContext(AuthContext);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, activeCompanyId]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/servers", {
                        params: { pageNumber, companyId: activeCompanyId },
                    });
                    dispatch({ type: "LOAD_USERS", payload: data.users });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, activeCompanyId]);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("user", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_USERS", payload: data.user });
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_USER", payload: +data.userId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleOpenUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(true);
    };

    const handleCloseUserModal = () => {
        setSelectedUser(null);
        setUserModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setUserModalOpen(true);
    };

    const handleDeleteUser = async (userId) => {
        try {
            await api.delete(`/users/${userId}`);
            toast.success(i18n.t("users.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingUser(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOpenQrModal = (user) => {
        setSelectedUser(user);
        setActiveCompanySelected(activeCompanyId);
        setQrModalOpen(true);
    };

    const handleCloseQrModal = useCallback(() => {
        setSelectedUser(null);
        setActiveCompanySelected(null);
        setQrModalOpen(false);
    }, [setQrModalOpen, setSelectedUser]);

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingUser &&
                    `${i18n.t("users.confirmationModal.deleteTitle")} ${
                        deletingUser.name
                    }?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteUser(deletingUser.id)}
            >
                {i18n.t("users.confirmationModal.deleteMessage")}
            </ConfirmationModal>

            <UserModal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="form-dialog-title"
                userId={selectedUser && selectedUser.id}
            />

            <QrcodeModalGenerator
                open={qrModalOpen}
                onClose={handleCloseQrModal}
                userId={selectedUser && selectedUser?.id}
                companyId={activeCompanySelected}
            />

            <MainHeader>
                <Title>Servers</Title>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <div width="100%">
                    <Grid spacing={3}>
                        <Grid item xs={6} md={6} style={{ display: "inline" }}>
                            {users.map((user) => (
                                <Card
                                    key={user.id}
                                    style={{ margin: 20, float: "left" }}
                                >
                                    <CardContent>
                                        <Typography
                                            sx={{ fontSize: 14 }}
                                            color="text.secondary"
                                            gutterBottom
                                            align="center"
                                        >
                                            {user.name}
                                        </Typography>
                                    </CardContent>
                                    <CardActions align="center">
                                        <Button
                                            size="small"
                                            variant="contained"
                                            style={{ width: "100%" }}
                                            color="primary"
                                            onClick={() => {
                                                window.open(
                                                    "/rate/" +
                                                        activeCompanyId +
                                                        "/" +
                                                        user.id,
                                                    "_blank"
                                                ); //to open new page
                                            }}
                                            align="center"
                                        >
                                            Calificar
                                        </Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        </MainContainer>
    );
};

export default Servers;
