import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import { yellow } from '@material-ui/core/colors';


import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Rating from "@material-ui/lab/Rating";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

export class RateFormStepA extends Component {

    constructor(props) {
        super(props);
        const { values, handleChange } = this.props;


    }




    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };



    render() {

        const classes = this.props.values.classes;
        const values = this.props.values;

        return (
            <React.Fragment>
                <MuiThemeProvider>
                    <>
                        <Dialog
                            open
                            fullWidth
                            maxWidth='sm'
                        >
                            <AppBar title="Add your review" />


                            <CssBaseline />
                            <Card square className={classes.paper}>
                                <CardMedia className={classes.media} image={values.companyBackground}
                                    title={values.companyName}
                                >
                                    {values.companyLogo === '' ? (
                                        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                                            <Typography className={classes.text} variant="h5" align="center">
                                                {values.companyName}
                                            </Typography>

                                        </Box>
                                    ) : (
                                            <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                                                <Avatar alt={values.companyName} src={values.companyLogo} className={classes.medium} />
                                            </Box>
                                        )}
                                </CardMedia>
                                <CardContent>

                                    <div align="center" className={classes.avatar}>
                                        <Avatar alt={values.employeeName} src={values.employeePicture} className={classes.medium} />
                                    </div>
                                    <div align="center">
                                        <Typography variant="h5" component="h2">
                                            {values.employeeName}
                                        </Typography>
                                        <Typography className={classes.pos} color="textSecondary">
                                            {values.companyName}
                                        </Typography>
                                        
                                    </div>
                                    


                                      {values.employeeReviews > 1 ? (
                                          <div align="center">
                                        <Typography className={classes.text} style={{ display: 'inline-flex', alignItems: 'center' }} variant="subtitle1" align="center" display="inline" variant="subtitle1">
                                            {values.employeeReviews} reviews
                                        </Typography>
                                         </div>
                                      ) : console.log("No hay employeereviews para mostrar")}

                                   
                                    <div align="center">
                                        <Button color="primary" size="large" variant="contained" className={classes.button} onClick={this.continue}>Calificame</Button>
                                    </div>
                                </CardContent>

                            </Card>



                        </Dialog>
                    </>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

export default RateFormStepA;