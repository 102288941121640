import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';


import { makeStyles } from '@material-ui/core/styles';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Rating from "@material-ui/lab/Rating";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

export class RateFormSucess extends Component {

    constructor(props) {
        super(props);
        const { values, handleChange } = this.props;


    }




    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };



    render() {

        const classes = this.props.values.classes;
        const values = this.props.values;
        const handleChange = this.props.handleChange;
        const handleTouch = this.props.handleTouch;
        const handleSubmit = this.props.handleSubmit;
        const errors = this.props.values.errors;
        const touched = this.props.touched;

        return (
            <React.Fragment>
                <MuiThemeProvider>
                    <>
                       
                            <AppBar title="Add your review" />


                            <CssBaseline />
                            <Card square className={classes.paper}>
                            <CardMedia className={classes.media} image={values.companyBackground}
                                    title={values.companyName}
                                >
                                    
                                        <Box display="flex" justifyContent="center" alignItems="center" p={2}>
                                           <Avatar alt={values.companyName} src={values.companyLogo} className={classes.medium} />
                                        </Box>
                                    
                                </CardMedia>
                                <div align="center">
                                    <Typography className={classes.text} variant="h5" align="center">
                                        {values.companyName}
                                    </Typography>
                                </div>
                                <div align="center" className={classes.avatar}>
                                {values.sucessMessage}
                                </div>

                                <div align="center">
                                    <   Typography className={classes.text} variant="subtitle2" align="center">
                                        Dejarias propina utilizando esta app?
                                    </Typography>




                                    <Grid component="label" container align="center" justify="center" alignItems="center" spacing={1}>
                                        <Grid item>No</Grid>
                                        <Grid item>
                                            <Switch
                                                checked={values.willPayTip}
                                                onChange={handleChange('willPayTip')}

                                                color="primary"
                                                name="willPayTip"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}

                                            />
                                        </Grid>
                                        <Grid item>Si</Grid>
                                    </Grid>

                                </div>

                                <div align="center">
                                    <Button color="primary" size="large" variant="contained" className={classes.button} onClick={handleSubmit}>Finalizar encuesta</Button>
                                </div>
                                



                            </Card>



                        
                    </>
                </MuiThemeProvider>
            </React.Fragment>
        );
    }
}

export default RateFormSucess;